// ImageCarousel.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from "../assets/banner1.png"
import img2 from "../assets/banner2.png"
import img3 from "../assets/banner3.png"

const Carousel = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <></>,
  };

  return (
    <div style={{ width: '100%' }}>
      <Slider {...settings}>
        <div>
          <img src={img1} alt="ImageDog" style={{ width: '100%', height: '100%' }} />
        </div>
        <div>
          <img src={img2} alt="ImageDog2" style={{ width: '100%', height: '100%' }} />
        </div>
        <div>
          <img src={img3} alt="ImageDog3" style={{ width: '100%', height: '100%' }} />
        </div>
      </Slider>
    </div>
  );
};

export default Carousel;
