import logo from './assets/Logo.svg';
import './App.css';
import Carousel from './components/Carousel';
import Header from './components/Header';
import banner from './assets/banner.png';
import about from './assets/about.png';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img8 from './assets/img8.png';
import img9 from './assets/img9.png';
import img10 from './assets/img10.png';
import img11 from './assets/img11.png';
import img12 from './assets/img12.png';
import img13 from './assets/img13.png';
import img14 from './assets/img14.png';
import TG from './assets/TG.svg';
import TW from './assets/TW.png';

function App() {
  
  return (
    <div className="App">
      <Header/>
      <Carousel/>
      <div className="section">
        <div className="sectionInside">
          <div className="row">
            <div className="feturesBlock">
              <div className="featureWidget">
                <p className="catHeading">LATEST DOGZZERS VIDEOS</p>
              </div>
              <div className="featureWidget">
                <p className="viewall">View all</p>
              </div>
            </div>
            <div className="imgBlock">
              <img className="img" src={img1} alt="img"/>
              <img className="img" src={img2} alt="img"/>
              <img className="img" src={img3} alt="img"/>
              <img className="img" src={img4} alt="img"/>
            </div>
          </div>
          <div className="row">
            <div className="feturesBlock">
              <div className="featureWidget">
                <p className="catHeading">DOGZZERS VIRAL VIDEOS</p>
              </div>
            </div>
            <div className="imgBlock">
              <img className="img" src={img5} alt="img"/>
              <img className="img" src={img6} alt="img"/>
              <img className="img" src={img7} alt="img"/>
              <img className="img" src={img8} alt="img"/>
            </div>
          </div>
          <div className="bannerBlock">
            <img alt="banner" src={banner} className="banner"/>
          </div>
          <div className="row">
            <div className="feturesBlock">
              <div className="featureWidget">
                <p className="catHeading">DOGZZERS VIRAL VIDEOS</p>
              </div>
            </div>
            <div className="imgBlock">
              <img className="img" src={img9} alt="img"/>
              <img className="img" src={img10} alt="img"/>
              <img className="img" src={img11} alt="img"/>
              <img className="img" src={img12} alt="img"/>
            </div>
          </div>
          <div className="about">
            <h1 className="heading">WE'VE GOT YOU <span className="yellow">COVERED</span></h1>
            <div className="aboutFlex">
              <div className="aboutLeft">
                <img className="aboutImg" src={about} alt="alt"/>
              </div>
              <div className="aboutRight">
                <p className="subheading">WE DO DILFS BEST</p>
                <p className="description">Dilf corn is next to impossible to beat when wanting a quick fix of the most alluring experienced dogstars around. Here at Dogzzers.com, the best work of buxom mature vixens like Shiba Inu, DOGE, and MYRO can be found in a matter of seconds. The hardcore funny videos you’ll behold will quickly prove that dilfs like it big any time of day or night. Catch Dogzzers taking on a huge cook with ease in every hungry orifice until it explodes uncontrollably with satisfaction.</p>
                <div className="aboutVidsBlock">
                <img className="img" src={img13} alt="img"/>
                <img className="img" src={img14} alt="img"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <img className="logo" src={logo} alt="logo" />
        <div className="socialBlock">
          <a href="https://t.me/dogzzers" target="_blank"
          rel="noopener noreferrer"><img alt="telegram" src={TG} className="social1"/></a>
          <a href="https://twitter.com/dogzzers" target="_blank"
          rel="noopener noreferrer"><img alt="twitter" src={TW} className="social2"/></a>
        </div>
      </div>
    </div>
  );
}

export default App;
