import './Header.css';
import search from '../assets/search.png'
import logo from '../assets/Logo.svg'

function Header() {
  
  return (
    <div className="Header">
      <div className="header">
        <div className="header-inside">
          <div className="left">
            <div className="logoBlock">
              <img alt="logo" className="logo" src={logo}/>
            </div>
            <div className="items">
                  <p className="menuItem">
                    DOG VIDEOS
                  </p>
                  <p className="menuItem">
                    DOGSTARS
                  </p>
                  <p className="menuItem">
                    CATEGORIES
                  </p>
                  <p className="menuItem">
                    SITES
                  </p>
            </div>
          </div>
          <div  className="right">
            <div className="searchBlock">
              <img src={search} alt="search" className="search" />
            </div>
            <div className="buttonBlock">
              <a href="https://t.me/dogzzers" target="_blank"
          rel="noopener noreferrer"><button className="button">MEMEBER AREA</button></a>
              <a href="https://t.me/dogzzers" target="_blank"
          rel="noopener noreferrer"><button className="button2">JOIN NOW</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;